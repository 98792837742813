import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
// import Img from "gatsby-image"
import { Link } from "gatsby"

const SingleTagTemplate = ({data, pageContext}) => {
  const { posts, tag } = pageContext
  //console.log(posts);

  let portfolio = [];
  let archive = [];

  posts.forEach((post) => {
    if (post.frontmatter.is_portfolio === "yes") {
        portfolio.push(post);
      }
    else archive.push(post);
    })

  portfolio = portfolio.map((post) => (
    <div>
      <a href={post.frontmatter.slug} style={{textDecoration:"none", width:"auto", height:"auto"}}>
      <div className="feature" style={{height:"500", display:"block"}}key={post.frontmatter.slug}>
      { !!post.frontmatter.featureImage &&
        <img src={post.frontmatter.featureImage.publicURL} alt={post.frontmatter.title}/>
      }
      <p className="overlay">
        {
          post.frontmatter.tags.map((tag,index) =>
          {
            return (
              <span>
              <Link className="tag-link" to={`../../tags/${tag}`}>
              {tag}
              </Link>
              { (index < post.frontmatter.tags.length-1) ? ', ' : '' }
              </span>
            )
          })
        }
      </p>
      <h3 style={{paddingTop:5, paddingBottom:10}}>{post.frontmatter.title}</h3>
      </div>
      </a>
    </div>
  ));

  archive = archive.map((post) => (
    <div>
      <a href={post.frontmatter.slug} style={{textDecoration:"none", width:"auto", height:"auto"}}>
      <div className="feature" style={{height:"500", display:"block"}}key={post.frontmatter.slug}>
      { !!post.frontmatter.featureImage &&
        <img src={post.frontmatter.featureImage.publicURL} alt={post.frontmatter.title}/>
      }
      <p className="overlay">
        {
          post.frontmatter.tags.map((tag,index) =>
          {
            return (
              <span>
              <Link className="tag-link" to={`../../tags/${tag}`}>
              {tag}
              </Link>
              { (index < post.frontmatter.tags.length-1) ? ', ' : '' }
              </span>
            )
          })
        }
      </p>
      <h3 style={{paddingTop:5, paddingBottom:10}}>{post.frontmatter.title}</h3>
      </div>
      </a>
    </div>
  ));

  return (
    <Layout>
      <Helmet>
        <title>Tags: {tag.charAt(0).toUpperCase() + tag.slice(1)}</title>
      </Helmet>
    <div>
      <h1>
        {tag.charAt(0).toUpperCase() + tag.slice(1)}
      </h1>

      <div className="grid" style={{marginTop: 30}}>
        {portfolio}
      </div>
    </div>

    <div>
      <h1>
        Archive
      </h1>

      <div className="grid" style={{marginTop: 30}}>
        {archive}
      </div>
    </div>

    </Layout>
  )
}

export default SingleTagTemplate
