import React from "react"
import { Link } from "gatsby"

    const isPartiallyActive = ({
      isPartiallyCurrent
    }) => {
      return isPartiallyCurrent
        // ? { className: "active-header"}
        ? { style: {color: `#000`}}
        : {}
    }

    // const PartialNavLink = props => (
    //   <Link getProps={isPartiallyActive} {...props} />
    // )
const ListLink = props => (
  <li className="header-link"><Link getProps={isPartiallyActive} to={props.to}>{props.children}</Link>
  </li>
)


export default function Layout({ children }) {
  return (
    <div>
     <header className="header-home">
       <p className="header-name">
        <Link to="/" style={{ textDecoration: `none`, textShadow: `none`, backgroundImage: `none`, color: `#000` }}>
         X
       </Link>
       </p>
       {/* <ul className="header-list">
          <ListLink to="/work/"> Work </ListLink>
          <ListLink to="/notes/"> Notes </ListLink>
          <ListLink to="/about/"> About </ListLink>
       </ul> */}
        <ul className="header-list">
          <ListLink to="/entanglement"> Map </ListLink>
          <ListLink to="/notes/all-pages/"> Notes </ListLink>
       </ul>
    </header>

     <div className="content">
     {children}
    </div>
   </div>
  )
}
